import Aos from "aos";
import "aos/dist/aos.css";
import { useEffect, useRef, useState } from "react";
import "./App.scss";
import IconCommunity from "./assets/images/icon-community.png";
import IconList1 from "./assets/images/icon-list-1.png";
import IconList2 from "./assets/images/icon-list-2.png";
import IconList3 from "./assets/images/icon-list-3.png";
import ImageLogo from "./assets/images/img-logo.png";
import IconCard1 from "./assets/images/icon-card-0.png";
import IconCard2 from "./assets/images/icon-card-1.png";
import IconCard3 from "./assets/images/icon-card-2.png";
import IconCard4 from "./assets/images/icon-card-3.png";
import IconCard5 from "./assets/images/icon-card-4.png";
import IconCard6 from "./assets/images/icon-card-5.png";
import ImageLine from "./assets/images/img-line.png";
import IconInfo1 from "./assets/images/icon-info-1.png";
import IconInfo2 from "./assets/images/icon-info-2.png";
import IconInfo3 from "./assets/images/icon-info-3.png";
import IconDes from "./assets/images/icon-des.png";
import Marquee from "react-fast-marquee";
import IconDex1 from "./assets/images/icon-dex-1.png";
import IconDex2 from "./assets/images/icon-dex-2.png";
import IconDex3 from "./assets/images/icon-dex-3.png";
import IconDex4 from "./assets/images/icon-dex-4.png";
import IconDex5 from "./assets/images/icon-dex-5.png";
import IconDex6 from "./assets/images/icon-dex-6.png";
import IconDex7 from "./assets/images/icon-dex-7.png";
import IconDex8 from "./assets/images/icon-dex-8.png";
import IconDex9 from "./assets/images/icon-dex-9.png";
import IconDex10 from "./assets/images/icon-dex-10.png";
import IconDex11 from "./assets/images/icon-dex-11.png";
import IconArrow from "./assets/images/icon-arrow.png";
import ImageLogoWhite from "./assets/images/img-logo-white.png";
import IconQuote from "./assets/images/icon-quote.png";
import IconSnipe1 from "./assets/images/icon-snipe-1.png";
import IconSnipe2 from "./assets/images/icon-snipe-2.png";
import IconArrowLeft from "./assets/images/icon-arrow-left.png";
import IconArrowRight from "./assets/images/icon-arrow-right.png";
import IconMenu1 from "./assets/images/icon-menu-1.png";
import IconMenu2 from "./assets/images/icon-menu-2.png";
import IconMenu3 from "./assets/images/icon-menu-3.png";
import IconMenu4 from "./assets/images/icon-menu-4.png";
import IconMenu5 from "./assets/images/icon-menu-5.png";
import IconMenu6 from "./assets/images/icon-menu-6.png";
import IconMenu7 from "./assets/images/icon-menu-7.png";
import IconMenu8 from "./assets/images/icon-menu-8.png";
import IconMenu from "./assets/images/icon-menu.png";
import IconClose from "./assets/images/icon-close.png";
import ImageLogoSP from "./assets/images/img-logo-sp.png";
import ImageIntroRight from "./assets/images/intro-right.png";
import BgIntro1 from "./assets/images/bg-intro-1.png";
import BgIntro2 from "./assets/images/bg-intro-2.png";
import BgIntro3 from "./assets/images/bg-intro-3.png";

const slides = [
  {
    title: "Ease of Use",
    subTitle: "Trade Effortlessly, Anytime.",
    description:
      "Users appreciate the intuitive interface, which makes it accessible for both beginners and experienced traders.",
  },
  {
    title: "Security",
    subTitle: "Decentralized Protection for Peace of Mind.",
    description:
      "The bot ensures secure transactions through decentralized cloud data management, which minimizes the risk of slippage.",
  },
  {
    title: "Speed",
    subTitle: "Lightning-Fast Transactions, Seamless Execution.",
    description:
      "TonTradingBot is praised for its lightning-fast transaction speeds, thanks to its compatibility with DEX platforms.",
  },
  {
    title: "Comprehensive Features",
    subTitle: "Everything You Need in One Place.",
    description:
      "It offers a range of features, including copy trading, staking, and a five-level referral system that allows users to earn passive income.",
  },
  {
    title: "Community Support",
    subTitle: "Backed by a Thriving and Supportive Network.",
    description:
      "The bot has a strong community presence on platforms like Telegram, Twitter, and Discord, providing users with ample support and resources.",
  },
];

function App() {
  const [currentSlide, setCurrentSlide] = useState(0);
  const [isShow, setIsShow] = useState();
  const menuRef = useRef(null);
  const [isOpenMenu, setOpenMenu] = useState(false);

  const nextSlide = () => {
    setCurrentSlide(currentSlide === slides.length - 1 ? 0 : currentSlide + 1);
  };

  const prevSlide = () => {
    setCurrentSlide(currentSlide === 0 ? slides.length - 1 : currentSlide - 1);
  };

  const onShowDropdown = (value) => {
    setIsShow((prev) => (prev === value ? null : value));
  };

  const handleClickOutside = (event) => {
    if (menuRef.current && !menuRef.current.contains(event.target)) {
      setIsShow(null);
    }
  };

  const [activeMenu, setActiveMenu] = useState(null);

  const toggleMenu = (menu) => {
    setActiveMenu(activeMenu === menu ? null : menu);
  };

  useEffect(() => {
    document.addEventListener("mousedown", handleClickOutside);
    return () => {
      document.removeEventListener("mousedown", handleClickOutside);
    };
  }, []);

  useEffect(() => {
    Aos.init({
      duration: 1500,
    });
  }, []);

  return (
    <div className="App">
      <main className="page-main">
        <section data-aos="fade-up" className="section-intro">
          <div className="container">
            <div className="page-header">
              <nav className="nav-menu">
                <div className="img-logo">
                  <img src={ImageLogo} alt="logo" />
                </div>
                <div className="img-logo-sp">
                  <img src={ImageLogoSP} alt="logo" />
                </div>
                <ul ref={menuRef}>
                  <li>
                    <a href="/">Home</a>
                  </li>
                  <li onClick={() => onShowDropdown(1)}>
                    Feature
                    <svg
                      width={12}
                      xmlns="http://www.w3.org/2000/svg"
                      viewBox="0 0 448 512"
                      className={`${isShow === 1 ? "active-drop" : ""}`}
                    >
                      <path d="M201.4 374.6c12.5 12.5 32.8 12.5 45.3 0l160-160c12.5-12.5 12.5-32.8 0-45.3s-32.8-12.5-45.3 0L224 306.7 86.6 169.4c-12.5-12.5-32.8-12.5-45.3 0s-12.5 32.8 0 45.3l160 160z" />
                    </svg>
                    <ul
                      className="dropdown-menu"
                      style={{ display: isShow === 1 ? "block" : "none" }}
                    >
                      <li>
                        <img src={IconMenu1} alt="" />
                        <a
                          href="https://t.me/tontrade"
                          target="_blank"
                          rel="noopener noreferrer"
                        >
                          Trading Bot
                        </a>
                      </li>
                      <li>
                        <img src={IconMenu2} alt="" />
                        <a
                          href="https://t.me/tontrade"
                          target="_blank"
                          rel="noopener noreferrer"
                        >
                          Sniper Bot
                        </a>
                      </li>
                      <li>
                        <img src={IconMenu3} alt="" />
                        <a
                          href="https://t.me/tontrade"
                          target="_blank"
                          rel="noopener noreferrer"
                        >
                          Trading Monitor
                        </a>
                      </li>
                      <li>
                        <img src={IconMenu4} alt="" />
                        <a
                          href="https://t.me/tontrade"
                          target="_blank"
                          rel="noopener noreferrer"
                        >
                          Copy Trading
                        </a>
                      </li>
                      <li>
                        <img src={IconMenu5} alt="" />
                        <a
                          href="https://t.me/tontrade"
                          target="_blank"
                          rel="noopener noreferrer"
                        >
                          Token Transfer
                        </a>
                      </li>
                      <li>
                        <img src={IconMenu6} alt="" />
                        <a
                          href="https://t.me/tontrade"
                          target="_blank"
                          rel="noopener noreferrer"
                        >
                          Mass Wallet Creator
                        </a>
                      </li>
                    </ul>
                  </li>
                  <li onClick={() => onShowDropdown(2)}>
                    Resources
                    <svg
                      width={12}
                      xmlns="http://www.w3.org/2000/svg"
                      viewBox="0 0 448 512"
                      className={`${isShow === 2 ? "active-drop" : ""}`}
                    >
                      <path d="M201.4 374.6c12.5 12.5 32.8 12.5 45.3 0l160-160c12.5-12.5 12.5-32.8 0-45.3s-32.8-12.5-45.3 0L224 306.7 86.6 169.4c-12.5-12.5-32.8-12.5-45.3 0s-12.5 32.8 0 45.3l160 160z" />
                    </svg>
                    <ul
                      className="dropdown-menu"
                      style={{ display: isShow === 2 ? "block" : "none" }}
                    >
                      <li>
                        <img src={IconMenu7} alt="" />
                        <a
                          href="https://tontradingbot.gitbook.io/docs"
                          target="_blank"
                          rel="noopener noreferrer"
                        >
                          Documentations
                        </a>
                      </li>
                      <li>
                        <img src={IconMenu8} alt="" />
                        <a href="#">Github</a>
                      </li>
                    </ul>
                  </li>
                  <li>
                    <a
                      href="https://t.me/TonTradingBot_Annoucement"
                      target="_blank"
                      rel="noopener noreferrer"
                    >
                      Get in touch
                    </a>
                  </li>
                </ul>
                <div className="right">
                  <a
                    href="https://t.me/tontrade"
                    target="_blank"
                    rel="noopener noreferrer"
                    className="btn-action"
                  >
                    Open Bot
                  </a>
                  <a
                    href=""
                    className="btn-menu"
                    onClick={(e) => {
                      e.preventDefault();
                      setOpenMenu(!isOpenMenu);
                      document.body.style.overflow = "hidden";
                      window.scrollTo({ top: 0 });
                    }}
                  >
                    <img src={IconMenu} alt="" />
                  </a>
                </div>
              </nav>
            </div>
            <div className="section-intro-main">
              <div className="intro-bg-1">
                <img src={BgIntro1} alt="" />
              </div>
              <div className="intro-bg-2">
                <img src={BgIntro2} alt="" />
              </div>
              <div className="content">
                <div className="main">
                  First Trading,
                  <div className="line">
                    TON’s Chain Epoch.
                    <img src={ImageLine} alt="" />
                  </div>
                </div>
                <p className="sub">
                  Access a completely new trading style on TON through the most
                  advanced sniper BOT right on Telegram.
                </p>
                <div className="actions">
                  <a
                    href="https://t.me/tontrade"
                    target="_blank"
                    rel="noopener noreferrer"
                    className="btn-trade"
                  >
                    Start Trading Now!
                  </a>
                  <a
                    href="https://t.me/TonTradingBot_Annoucement"
                    target="_blank"
                    rel="noopener noreferrer"
                    className="btn-community"
                  >
                    <img src={IconCommunity} alt="" />
                    Our Community
                  </a>
                </div>
                <ul className="list">
                  <li>
                    <img src={IconList1} alt="" />
                    Lightning Speed
                  </li>
                  <li>
                    <img src={IconList2} alt="" />
                    Precision
                  </li>
                  <li>
                    <img src={IconList3} alt="" />
                    Safety
                  </li>
                </ul>
              </div>
              <div className="intro-right">
                <img src={ImageIntroRight} alt="intro" />
                <div className="intro-bg-3">
                  <img src={BgIntro3} alt="" />
                </div>
              </div>
            </div>
          </div>
        </section>
        <section data-aos="fade-up" className="section-about">
          <div className="container wrapper">
            <p className="title">Professional Sniper BOT using Private Nodes</p>
            <p className="description">
              TonTradingBot integrates easily with the DEX platform on TON.
              Trade securely with unprecedented ease and tap into the liquidity
              of these decentralized ecosystems.
            </p>
            <div className="list-card">
              <div className="card">
                <img src={IconCard1} alt="" />
                <div className="content">
                  <p className="title">Lightning Speed Swap</p>
                  <p className="des">
                    Absolute compatibility with DEXs. Ensures maximum
                    multi-threaded transaction speed.
                  </p>
                </div>
              </div>
              <div className="card">
                <img src={IconCard2} alt="" />
                <div className="content">
                  <p className="title">Security Takes Priority</p>
                  <p className="des">
                    A decentralized cloud data management method ensures
                    security and reliability.
                  </p>
                </div>
              </div>
              <div className="card">
                <img src={IconCard3} alt="" />
                <div className="content">
                  <p className="title">Private Transactions</p>
                  <p className="des">
                    User transactions are executed privately, minimizing losses
                    due to slippage.
                  </p>
                </div>
              </div>
              <div className="card">
                <img src={IconCard4} alt="" />
                <div className="content">
                  <p className="title">Direct Referral System</p>
                  <p className="des">
                    Referral other users to use BOT to receive passive
                    commissions.
                  </p>
                </div>
              </div>
              <div className="card">
                <img src={IconCard5} alt="" />
                <div className="content">
                  <p className="title">Flat UI Easy To Use</p>
                  <p className="des">
                    Intuitive and simple interface even for new or advanced
                    users.
                  </p>
                </div>
              </div>
              <div className="card">
                <img src={IconCard6} alt="" />
                <div className="content">
                  <p className="title">Realtime Chart</p>
                  <p className="des">
                    Provides live market updates, enabling quick trades and
                    limit orders in one interface.
                  </p>
                </div>
              </div>
            </div>
            <a
              href="https://t.me/tontrade"
              target="_blank"
              rel="noopener noreferrer"
              className="btn-action"
            >
              Start trading directly on Telegram!
            </a>
          </div>
        </section>
        <section data-aos="fade-up" className="section-information">
          <div className="container wrapper">
            <div className="frame-info">
              <ul className="list-info">
                <li>
                  <img src={IconInfo1} alt="" />
                  <div className="content">
                    <p className="title">Fast and Secure Trading on TON</p>
                    <p className="des">
                      <img src={IconDes} alt="" />
                      <span>
                        Experience the first-ever TON sniper bot working
                        seamlessly on Telegram using private nodes.
                      </span>
                    </p>
                    <p className="des">
                      <img src={IconDes} alt="" />
                      <span>
                        Trade securely on a decentralized protocol with
                        unparalleled lightning speed.
                      </span>
                    </p>
                  </div>
                </li>
                <li>
                  <img src={IconInfo2} alt="" />
                  <div className="content">
                    <p className="title">Advanced Features for Smart Traders</p>
                    <p className="des">
                      <img src={IconDes} alt="" />
                      <span>
                        Stay ahead with real-time cryptocurrency chart updates
                        and direct monitor services.
                      </span>
                    </p>
                    <p className="des">
                      <img src={IconDes} alt="" />
                      <span>
                        Replicate success with copy trading, following the
                        strategies of top traders.
                      </span>
                    </p>
                  </div>
                </li>
                <li>
                  <img src={IconInfo3} alt="" />
                  <div className="content">
                    <p className="title">All-in-One Cryptocurrency Solutions</p>
                    <p className="des">
                      <img src={IconDes} alt="" />
                      <span>
                        Transfer cryptocurrencies directly on Telegram without
                        the need for third-party wallets.
                      </span>
                    </p>
                    <p className="des">
                      <img src={IconDes} alt="" />
                      <span>
                        Create mass TON wallets privately and securely within
                        Telegram.
                      </span>
                    </p>
                  </div>
                </li>
              </ul>
            </div>
            <div className="info-content">
              <p className="sub-des">
                Analyzing the Trading Volume of TonTradingBot: A Deep Dive into
                TON's Decentralized Ecosystem
              </p>
              <p className="value">$108,917,628+</p>
              <p className="note">Total Trading Volume on TonTradingBot</p>
            </div>
          </div>
          <div className="slider">
            <Marquee>
              <div className="marquee">
                <div className="slider-content">
                  <img src={IconDex1} alt="" />
                </div>
                <div className="slider-content">
                  <img src={IconDex2} alt="" />
                </div>
                <div className="slider-content">
                  <img src={IconDex3} alt="" />
                </div>
                <div className="slider-content">
                  <img src={IconDex4} alt="" />
                </div>
                <div className="slider-content">
                  <img src={IconDex5} alt="" />
                </div>
                <div className="slider-content">
                  <img src={IconDex6} alt="" />
                </div>
                <div className="slider-content">
                  <img src={IconDex7} alt="" />
                </div>
                <div className="slider-content">
                  <img src={IconDex8} alt="" />
                </div>
                <div className="slider-content">
                  <img src={IconDex9} alt="" />
                </div>
                <div className="slider-content">
                  <img src={IconDex10} alt="" />
                </div>
                <div className="slider-content">
                  <img src={IconDex11} alt="" />
                </div>
              </div>
            </Marquee>
            <Marquee direction="right">
              <div className="marquee">
                <div className="slider-content">
                  <img src={IconDex1} alt="" />
                </div>
                <div className="slider-content">
                  <img src={IconDex2} alt="" />
                </div>
                <div className="slider-content">
                  <img src={IconDex3} alt="" />
                </div>
                <div className="slider-content">
                  <img src={IconDex4} alt="" />
                </div>
                <div className="slider-content">
                  <img src={IconDex5} alt="" />
                </div>
                <div className="slider-content">
                  <img src={IconDex6} alt="" />
                </div>
                <div className="slider-content">
                  <img src={IconDex7} alt="" />
                </div>
                <div className="slider-content">
                  <img src={IconDex8} alt="" />
                </div>
                <div className="slider-content">
                  <img src={IconDex9} alt="" />
                </div>
                <div className="slider-content">
                  <img src={IconDex10} alt="" />
                </div>
                <div className="slider-content">
                  <img src={IconDex11} alt="" />
                </div>
              </div>
            </Marquee>
          </div>
        </section>
        <section data-aos="fade-up" className="section-snipe">
          <div className="container wrapper">
            <div className="left">
              <p className="title">Snipe on Gaspump and Ton.fun</p>
              <p className="subtitle">
                Maximize Profits with Precision Sniping Using TonTradingBot.
              </p>
            </div>
            <div className="right">
              <div className="card">
                <div className="icon-snipe">
                  <img src={IconSnipe1} alt="" />
                </div>
                <p className="title">Gas Pump</p>
                <p className="des">
                  Easy to snipe by pasting Dev Wallet to the Bot.
                </p>
                <a
                  href="https://t.me/tontrade"
                  target="_blank"
                  rel="noopener noreferrer"
                  className="btn-detail"
                >
                  <span>More Details</span>
                  <img src={IconArrow} alt="" />
                </a>
              </div>
              <div className="card">
                <div className="icon-snipe">
                  <img src={IconSnipe2} alt="" />
                </div>
                <p className="title">Ton.fun</p>
                <p className="des">
                  Capture the best chances on the platform with Snipe feature.
                </p>
                <a
                  href="https://t.me/tontrade"
                  target="_blank"
                  rel="noopener noreferrer"
                  className="btn-detail"
                >
                  <span>More Details</span>
                  <img src={IconArrow} alt="" />
                </a>
              </div>
            </div>
          </div>
        </section>
        <section data-aos="fade-up" className="section-referral">
          <div className="container wrapper">
            <div className="content">
              <div className="title">
                <div className="line">
                  Five-Level
                  <img src={ImageLine} alt="" />
                </div>
                Referral System
              </div>
              <ul className="list-ref">
                <li>
                  <p className="des">Commissions up to 50%</p>
                  <p className="sub-des">
                    Receive commissions up to 50% of trading fees.
                  </p>
                </li>
                <li>
                  <p className="des">Developer</p>
                  <p className="sub-des">
                    50% of the fee used for features development.
                  </p>
                </li>
                <li>
                  <p className="des">Referral</p>
                  <p className="sub-des">
                    30% of the fee is paid directly to the referrer. Get 5%
                    extra commissions from 4 next levels.
                  </p>
                </li>
              </ul>
              <a
                href="https://t.me/tontrade"
                target="_blank"
                rel="noopener noreferrer"
                className="btn-ref"
              >
                Get Your Referral Link
              </a>
            </div>
          </div>
        </section>
        <section data-aos="fade-up" className="section-why">
          <div className="container wrapper">
            <div className="left">
              <p className="title">Why TonTradingBot?</p>
              <p className="des">
                We are committed to providing a seamless and feature-rich
                platform that not only enhances trading strategies but also
                fosters a community of informed and empowered traders.
              </p>
              <div className="actions">
                <div onClick={prevSlide} className="btn-action">
                  <img src={IconArrowLeft} alt="" />
                </div>
                <div onClick={nextSlide} className="btn-action">
                  <img src={IconArrowRight} alt="" />
                </div>
              </div>
            </div>
            <div className="right">
              <div
                style={{
                  position: "relative",
                  width: "100%",
                  overflow: "hidden",
                }}
              >
                <ul
                  className="list-answers"
                  style={{
                    display: "flex",
                    transform: `translateX(-${currentSlide * 45}%)`,
                    transition: "transform 0.5s",
                  }}
                >
                  {slides.map((item, index) => (
                    <li
                      className="card"
                      key={index}
                      style={{ flex: "0 0 100%", width: "100%" }}
                    >
                      <div className="title">
                        <p className="title-1">{item.title}</p>
                        <p className="title-2">{item.subTitle}</p>
                      </div>
                      <p className="des">{item.description}</p>
                      <div className="icon">
                        <img src={IconQuote} alt="" />
                      </div>
                    </li>
                  ))}
                </ul>
              </div>
            </div>
          </div>
        </section>
      </main>
      <footer className="page-footer">
        <div data-aos="fade-up" className="container wrapper">
          <ul className="list-content">
            <li>
              <img src={ImageLogoWhite} alt="" />
            </li>
            <li>
              <a href="/">HOME</a>
            </li>
            <li>
              FEATURES
              <ul className="sub-menu">
                <a
                  href="https://t.me/tontrade"
                  target="_blank"
                  rel="noopener noreferrer"
                >
                  Trading Bot
                </a>
                <a
                  href="https://t.me/tontrade"
                  target="_blank"
                  rel="noopener noreferrer"
                >
                  Sniper Bot
                </a>
                <a
                  href="https://t.me/tontrade"
                  target="_blank"
                  rel="noopener noreferrer"
                >
                  Trading Monitor
                </a>
                <a
                  href="https://t.me/tontrade"
                  target="_blank"
                  rel="noopener noreferrer"
                >
                  Copy Trading
                </a>
                <a
                  href="https://t.me/tontrade"
                  target="_blank"
                  rel="noopener noreferrer"
                >
                  Token Transfer
                </a>
                <a
                  href="https://t.me/tontrade"
                  target="_blank"
                  rel="noopener noreferrer"
                >
                  Mass Wallet Creator
                </a>
              </ul>
            </li>
            <li>
              RESOURCES
              <ul className="sub-menu">
                <a
                  href="https://tontradingbot.gitbook.io/docs"
                  target="_blank"
                  rel="noopener noreferrer"
                >
                  Docs
                </a>
                <a>Github</a>
              </ul>
            </li>
            <li>
              CONTACT US
              <ul className="sub-menu">
                <a
                  href="https://t.me/TonTradingBot_Annoucement"
                  target="_blank"
                  rel="noopener noreferrer"
                >
                  Telegram
                </a>
                <a
                  href="https://x.com/TonTradingBot"
                  target="_blank"
                  rel="noopener noreferrer"
                >
                  Twitter / X
                </a>
                <a>Discord</a>
              </ul>
            </li>
          </ul>
        </div>
        <p className="copyright">
          © 2024 Tontradingbot team. All Rights Reserved.
        </p>
      </footer>
      <div
        className="header-menu"
        style={{ display: isOpenMenu ? "block" : "none" }}
      >
        <button
          onClick={(e) => {
            e.preventDefault();
            setOpenMenu(false);
            document.body.style.overflow = "auto";
          }}
          className="btn-close"
        >
          <img src={IconClose} alt="" />
        </button>
        <ul className="menu">
          <li>
            <a href="/">Home</a>
          </li>
          <li>
            <button className="menu-item" onClick={() => toggleMenu("feature")}>
              FEATURE{" "}
              {activeMenu === "feature" ? (
                <svg
                  width={16}
                  xmlns="http://www.w3.org/2000/svg"
                  viewBox="0 0 448 512"
                >
                  <path d="M201.4 374.6c12.5 12.5 32.8 12.5 45.3 0l160-160c12.5-12.5 12.5-32.8 0-45.3s-32.8-12.5-45.3 0L224 306.7 86.6 169.4c-12.5-12.5-32.8-12.5-45.3 0s-12.5 32.8 0 45.3l160 160z" />
                </svg>
              ) : (
                <svg
                  width={12}
                  xmlns="http://www.w3.org/2000/svg"
                  viewBox="0 0 320 512"
                >
                  <path d="M278.6 233.4c12.5 12.5 12.5 32.8 0 45.3l-160 160c-12.5 12.5-32.8 12.5-45.3 0s-12.5-32.8 0-45.3L210.7 256 73.4 118.6c-12.5-12.5-12.5-32.8 0-45.3s32.8-12.5 45.3 0l160 160z" />
                </svg>
              )}
            </button>
            {activeMenu === "feature" && (
              <ul className="submenu">
                <a
                  href="https://t.me/tontrade"
                  target="_blank"
                  rel="noopener noreferrer"
                >
                  <img src={IconMenu1} alt="" />
                  <span>Trading Bot</span>
                </a>
                <a
                  href="https://t.me/tontrade"
                  target="_blank"
                  rel="noopener noreferrer"
                >
                  <img src={IconMenu2} alt="" />
                  <span>Sniper Bot</span>
                </a>
                <a
                  href="https://t.me/tontrade"
                  target="_blank"
                  rel="noopener noreferrer"
                >
                  <img src={IconMenu3} alt="" />
                  <span>Trading Monitor</span>
                </a>
                <a
                  href="https://t.me/tontrade"
                  target="_blank"
                  rel="noopener noreferrer"
                >
                  <img src={IconMenu4} alt="" />
                  <span>Copy Trading</span>
                </a>
                <a
                  href="https://t.me/tontrade"
                  target="_blank"
                  rel="noopener noreferrer"
                >
                  <img src={IconMenu5} alt="" />
                  <span>Token Transfer</span>
                </a>
                <a
                  href="https://t.me/tontrade"
                  target="_blank"
                  rel="noopener noreferrer"
                >
                  <img src={IconMenu6} alt="" />
                  <span>Mass Wallet Creator</span>
                </a>
              </ul>
            )}
          </li>
          <li>
            <button
              className="menu-item"
              onClick={() => toggleMenu("resource")}
            >
              RESOURCE{" "}
              {activeMenu === "resource" ? (
                <svg
                  width={16}
                  xmlns="http://www.w3.org/2000/svg"
                  viewBox="0 0 448 512"
                >
                  <path d="M201.4 374.6c12.5 12.5 32.8 12.5 45.3 0l160-160c12.5-12.5 12.5-32.8 0-45.3s-32.8-12.5-45.3 0L224 306.7 86.6 169.4c-12.5-12.5-32.8-12.5-45.3 0s-12.5 32.8 0 45.3l160 160z" />
                </svg>
              ) : (
                <svg
                  width={12}
                  xmlns="http://www.w3.org/2000/svg"
                  viewBox="0 0 320 512"
                >
                  <path d="M278.6 233.4c12.5 12.5 12.5 32.8 0 45.3l-160 160c-12.5 12.5-32.8 12.5-45.3 0s-12.5-32.8 0-45.3L210.7 256 73.4 118.6c-12.5-12.5-12.5-32.8 0-45.3s32.8-12.5 45.3 0l160 160z" />
                </svg>
              )}
            </button>
            {activeMenu === "resource" && (
              <ul className="submenu">
                <a
                  href="https://tontradingbot.gitbook.io/docs"
                  target="_blank"
                  rel="noopener noreferrer"
                >
                  <img src={IconMenu7} alt="" />
                  <span>Documentations</span>
                </a>
                <a>
                  <img src={IconMenu8} alt="" />
                  <span>Github</span>
                </a>
              </ul>
            )}
          </li>
          <li>
            <a
              href="https://t.me/TonTradingBot_Annoucement"
              target="_blank"
              rel="noopener noreferrer"
              className="menu-item"
            >
              GET IN TOUCH
            </a>
          </li>
        </ul>
      </div>
    </div>
  );
}

export default App;
